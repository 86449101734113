body {
  background: url("klokke.43437a3c.png") center / cover no-repeat fixed;
  font-family: Myriad Pro, Verdana, sans-serif !important;
}

.selected-class {
  font-weight: bold;
}

.align-right {
  text-align: right !important;
}

.align-center {
  text-align: center !important;
}

.table-placeholder {
  text-align: center;
  font-style: italic;
}

#classes-container {
  min-width: 7em;
  max-width: 10em;
  margin-bottom: 12px;
  margin-right: 8px;
}

#classes-table {
  width: 100%;
}

#runnerTable {
  width: 100% !important;
}

table {
  border-spacing: 0;
}

table thead tr {
  color: #fff;
  background-color: #00416a;
}

table tbody td {
  white-space: nowrap;
  background-color: #d6e4f2;
  padding: .23em !important;
}

table td, table th {
  border: 1px solid #fff;
  padding: .4em;
  font-size: .8em;
}

.combined-time-column {
  white-space: nowrap;
}

.clickable-div {
  cursor: pointer;
}

#runnerTable_wrapper {
  margin-right: 10px;
  display: inline-block;
}

#intermediate-points {
  display: inline-block;
}

.blue-header {
  color: #fff;
  background-color: #00416a;
  padding: .4em;
  font-size: .8em;
  font-weight: bold;
}

.blue-child {
  background-color: #d6e4f2;
  padding: .3em;
  font-size: .8em;
}

.intermediate-point {
  float: left;
  margin-bottom: 2px;
  margin-right: 2px;
}

.class-element {
  margin-top: 2px;
}

.blue-header.selected {
  background-color: #7594b3;
}

.blue-child.selected {
  background-color: #8aacce;
}

.blue-header.clickable-div:hover {
  background-color: #2c8dca;
}

.blue-child.clickable-div:hover {
  background-color: #b1c1d2;
}

.small-table-text {
  font-size: .4em;
}

#header {
  margin-bottom: 4px;
  display: flex;
}

#race-info {
  margin-right: 2px;
}

table.dataTable thead th {
  border-bottom: none;
  padding: .8em;
}

.recent-event, .recent-event td {
  color: #fff;
  background-color: #fa9600;
}

.index-header-logo, .event-header-logo {
  max-width: 90%;
  max-height: 100px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.index-footer-logo {
  height: 100%;
}

.index-footer-small-line {
  background-color: #7594b3;
  height: 10px;
}

.index-footer-wide-line {
  text-align: center;
  color: #fff;
  background-color: #00416a;
  height: 40px;
  margin-top: 6px;
  margin-bottom: 6px;
  padding: 3px;
  line-height: 10px;
}

.active-event td {
  color: #fff;
  background-color: #fa9600;
}

#index-table-div {
  width: 90%;
  margin: 10px auto 30px;
  display: block;
}

.index-footer-wide-child {
  vertical-align: middle;
  height: 100%;
  display: inline-block;
}

.index-footer-small-line, .index-footer-wide-line {
  margin-left: -8px;
  margin-right: -8px;
}

td a {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  color: inherit;
  width: 100%;
  display: block;
}

.event-blue-header-bar {
  color: #fff;
  text-align: center;
  background-color: #00416a;
  width: 88%;
  margin: 20px auto;
  font-size: 2.6em;
  font-weight: bold;
}

#event-body {
  width: 80%;
  margin: auto;
}

#event-body-content {
  border-color: #d6e4f2;
  border-bottom-style: solid;
  width: 100%;
  padding-bottom: 16px;
  display: inline-block;
}

#liveresultater-subheader {
  width: 70%;
  margin: auto auto 20px;
  font-size: .9em;
}

#event-description {
  text-align: center;
  color: #fa9600;
  margin-top: 20px;
  font-size: 2em;
  font-weight: bold;
}

#index-table {
  width: 100%;
}

.box {
  margin-bottom: 36px;
}

.box .header {
  color: #00416a;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
}

.box .subheader {
  text-align: center;
  font-size: .9em;
  font-style: italic;
}

#event-body-left-col, #event-body-right-col {
  float: left;
  width: 48%;
}

#event-body-left-col {
  border-color: #d6e4f2;
  border-right-style: solid;
}

ul {
  list-style-type: none;
}

ul li:before {
  content: "■";
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #00416a;
}

li a {
  padding-left: 16px;
}

#audio-play-controller {
  text-align: center;
  cursor: pointer;
  border: solid #6b737b;
  max-width: 90%;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.audio-status {
  text-align: center;
  font-size: .8em;
  font-style: italic;
  display: block;
}

.error-color {
  color: red;
}

.warning-color {
  color: #ce921d;
}

.ok-color {
  color: #24a74e;
}

#legs-children {
  width: 100%;
  display: table;
}

.legs-row {
  display: table-row;
}

.leg {
  text-align: center;
  display: table-cell;
}

.hidden {
  display: none !important;
}

.dataTables_paginate {
  margin-top: 0 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: .46em;
  font-size: .8em;
  line-height: .8em;
  color: #fff !important;
  background: #00416a !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  border: none;
  color: #fff !important;
  background: #7594b3 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: #2c8dca !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  color: #666 !important;
  cursor: default !important;
  background: #00416a !important;
}

#runnerTable_length {
  color: #000;
  margin-top: 6px;
  font-size: .8em;
}

#runnerTable_length select {
  font-size: .9em;
}

#table-heading {
  text-align: center;
  font-weight: bold;
}

#runner-stats {
  text-align: right;
  font-size: .7em;
  font-style: italic;
}

.table-header {
  color: #fff;
  background-color: #00416a;
  margin-bottom: 3px;
  padding: .3em;
}

.table-footer {
  justify-content: space-between;
  display: flex;
}

td.clickable-table-cell {
  cursor: pointer;
  color: #2c8dca;
  text-decoration: underline;
}

.combined-difference {
  font-size: .9em;
  font-style: italic;
}

.running-time {
  font-style: italic;
}

.stripes {
  background-image: repeating-linear-gradient(135deg, gray, gray 2px, #0000 2px 8px);
}

#export-buttons {
  display: inline-block;
}

button.excel-export-button {
  color: #fff !important;
  float: left !important;
  background: #00416a !important;
  border: none !important;
  border-radius: 0 !important;
  margin: .1em .1em 2px !important;
  padding: .2em !important;
  font-family: Myriad Pro, Verdana, sans-serif !important;
  font-size: .8em !important;
  font-style: italic !important;
  font-weight: bold !important;
}

button.excel-export-button:hover, button.excel-export-button:active, button.excel-export-button:focus {
  text-shadow: none !important;
  background: #2c8dca !important;
}

#loader {
  border: 16px solid #f3f3f3;
  border-top-color: #00416a;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin-left: auto;
  margin-right: auto;
  animation: 2s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

#sprint {
  flex-direction: row;
  margin-top: -1.2em;
  display: flex;
}

.sprint-round {
  flex-flow: column wrap;
  justify-content: space-evenly;
  display: flex;
}

.sprint-heat {
  margin-bottom: 8px;
  margin-right: 8px;
}

.sprint-table {
  margin: 0;
  width: 100% !important;
}

.language-flag {
  cursor: pointer;
  height: 1.7em;
}

.language-bar-float {
  float: right;
  white-space: nowrap;
  margin-left: 2px;
  padding: 1px;
}

.language-bar-absolute {
  white-space: nowrap;
  padding: 1px;
  position: absolute;
  right: 10px;
}

.change-font-size {
  cursor: pointer;
  vertical-align: top;
  display: inline;
}

.change-font-size.increase {
  font-size: 1.7em;
}

.change-font-size.decrease {
  font-size: 1.5em;
}

.modal {
  z-index: 1;
  background-color: #0006;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modal-container {
  background-color: #fff;
  width: 80%;
  margin: 15% auto;
}

.modal-content {
  padding: 1em;
  font-size: .9em;
}

.modal-header {
  color: #fff;
  background-color: #00416a;
  padding: .4em;
  font-size: 1.7em;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

#more-info {
  text-align: center;
  cursor: pointer;
  margin-top: 1em;
  font-size: .9em;
  font-style: italic;
}

.modal-content ul li:before {
  padding-right: 1em;
}

.animate-top {
  animation: .4s animatetop;
  position: relative;
}

@keyframes animatetop {
  from {
    opacity: 0;
    top: -300px;
  }

  to {
    opacity: 1;
    top: 0;
  }
}

@media screen and (width <= 720px) {
  #event-body, #index-table-div {
    width: 100%;
  }
}

table.erik-table {
  width: 100%;
}

table.erik-table tr th.sortable {
  cursor: pointer;
  padding-right: 1.8em;
  position: relative;
}

.up-arrow:before {
  content: "▲";
  opacity: .2;
  font-size: .8em;
  line-height: .9em;
  position: absolute;
  bottom: 50%;
  right: .5em;
}

.down-arrow:after {
  content: "▼";
  opacity: .2;
  font-size: .8em;
  line-height: 1em;
  position: absolute;
  top: 50%;
  right: .5em;
}

.up-arrow-active:before, .down-arrow-active:after {
  opacity: 1;
}

.pagination {
  color: #fff;
  cursor: pointer;
  background-color: #00416a;
  border: none;
  border-radius: 2px;
  margin-right: .2em;
  padding-top: .2em;
  padding-bottom: .2em;
  font-size: 1em;
}

.pagination.selected-page {
  background-color: #7594b3;
}

.pagination.disabled {
  color: #666;
}

.pagination-container {
  float: right;
  margin-top: 4px;
}

.pagination:hover:not(.disabled):not(.selected-page) {
  background-color: #7594b3;
}

.row-per-page {
  cursor: pointer;
  border-radius: 2px;
  margin-top: 4px;
  padding: .2em;
  font-size: .8em;
}

.row-per-page-label {
  font-size: .8em;
}
/*# sourceMappingURL=index.e52ecd05.css.map */
