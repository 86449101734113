body {
    font-family: "Myriad Pro", Verdana, sans-serif !important;
    background: url("images/klokke.png") no-repeat center;
    background-attachment: fixed;
    background-size: cover;
}

.selected-class {
    font-weight: bold;
}

.align-right {
    text-align: right !important;
}

.align-center {
    text-align: center !important;
}

.table-placeholder {
    text-align: center;
    font-style: italic;
}

#classes-container {
    min-width: 7em;
    max-width: 10em;
    margin-right: 8px;
    margin-bottom: 12px;
}

#classes-table {
    width: 100%;
}

#runnerTable {
    width: 100% !important;
}

table {
    border-spacing: 0px;
}

table thead tr {
    background-color: #00416a;
    color: #fff;
}

table tbody td {
    background-color: #d6e4f2;
    padding: 0.23em !important;
    white-space: nowrap;
}

table td,
table th {
    border-style: solid;
    border-width: 1px;
    border-color: #fff;
    font-size: 0.8em;
    padding: 0.4em;
}

.combined-time-column {
    white-space: nowrap;
}

.clickable-div {
    cursor: pointer;
}

#runnerTable_wrapper {
    display: inline-block;
    margin-right: 10px;
}

#intermediate-points {
    display: inline-block;
}

.blue-header {
    padding: 0.4em;
    background-color: #00416a;
    color: #fff;
    font-size: 0.8em;
    font-weight: bold;
}

.blue-child {
    padding: 0.3em;
    background-color: #d6e4f2;
    font-size: 0.8em;
}

.intermediate-point {
    float: left;
    margin-right: 2px;
    margin-bottom: 2px;
}

.class-element {
    margin-top: 2px;
}

.blue-header.selected {
    background-color: #7594b3;
}

.blue-child.selected {
    background-color: #8aacce;
}

.blue-header.clickable-div:hover {
    background-color: #2c8dca;
}

.blue-child.clickable-div:hover {
    background-color: #b1c1d2;
}

.small-table-text {
    font-size: 0.4em;
}

#header {
    display: flex;
    margin-bottom: 4px;
}

#race-info {
    margin-right: 2px;
}

table.dataTable thead th {
    border-bottom: none;
    padding: 0.8em;
}

.recent-event,
.recent-event td {
    background-color: #fa9600;
    color: #ffffff;
}

.index-header-logo,
.event-header-logo {
    max-height: 100px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.index-footer-logo {
    height: 100%;
}

.index-footer-small-line {
    background-color: #7594b3;
    height: 10px;
}

.index-footer-wide-line {
    background-color: #00416a;
    height: 40px;
    line-height: 10px;
    margin-top: 6px;
    margin-bottom: 6px;
    text-align: center;
    color: #ffffff;
    padding: 3px;
}

.active-event td {
    background-color: #fa9600;
    color: #ffffff;
}

#index-table-div {
    width: 90%;
    margin-top: 10px;
    margin-bottom: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.index-footer-wide-child {
    vertical-align: middle;
    height: 100%;
    display: inline-block;
}

.index-footer-small-line,
.index-footer-wide-line {
    margin-left: -8px;
    margin-right: -8px;
}

td a {
    text-decoration: inherit;
    color: inherit;
    display: block;
    width: 100%;
}

.event-blue-header-bar {
    background-color: #00416a;
    color: #ffffff;
    width: 88%;
    margin: auto;
    text-align: center;
    font-size: 2.6em;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
}

#event-body {
    width: 80%;
    margin: auto;
}

#event-body-content {
    border-bottom-style: solid;
    display: inline-block;
    width: 100%;
    border-color: #d6e4f2;
    padding-bottom: 16px;
}

#liveresultater-subheader {
    width: 70%;
    margin: auto;
    font-size: 0.9em;
    margin-bottom: 20px;
}

#event-description {
    text-align: center;
    color: #fa9600;
    font-size: 2em;
    font-weight: bold;
    margin-top: 20px;
}

#index-table {
    width: 100%;
}

.box {
    margin-bottom: 36px;
}

.box .header {
    color: #00416a;
    font-size: 1.2em;
    text-align: center;
    font-weight: bold;
}

.box .subheader {
    font-style: italic;
    text-align: center;
    font-size: 0.9em;
}

#event-body-left-col,
#event-body-right-col {
    width: 48%;
    float: left;
}

#event-body-left-col {
    border-right-style: solid;
    border-color: #d6e4f2;
}

ul {
    list-style-type: none;
}

ul li:before {
    content: '\25A0';
}

a {
    color: #000;
    text-decoration: none;
}

a:hover {
    color: #00416a;
}

li a {
    padding-left: 16px;
}

#audio-play-controller {
    border: solid;
    max-width: 90%;
    text-align: center;
    border-color: #6b737b;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.audio-status {
    text-align: center;
    font-style: italic;
    font-size: 0.8em;
    display: block;
}

.error-color {
    color: #ff0000;
}

.warning-color {
    color: #ce921d;
}

.ok-color {
    color: #24a74e;
}

#legs-children {
    display: table;
    width: 100%;
}

.legs-row {
    display: table-row;
}

.leg {
    display: table-cell;
    text-align: center;
}

.hidden {
    display: none !important;
}

.dataTables_paginate {
    margin-top: 0 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    background: #00416a !important;
    color: #fff !important;
    font-size: 0.8em;
    line-height: 0.8em;
    padding: 0.46em;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    background: #7594b3 !important;
    color: #fff !important;
    border: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    background: #2c8dca !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
    color: #666 !important;
    cursor: default !important;
    background: #00416a !important;
}

#runnerTable_length {
    color: #000;
    margin-top: 6px;
    font-size: 0.8em;
}

#runnerTable_length select {
    font-size: 0.9em;
}

#table-heading {
    text-align: center;
    font-weight: bold;
}

#runner-stats {
    text-align: right;
    font-size: 0.7em;
    font-style: italic;
}

.table-header {
    background-color: #00416a;
    margin-bottom: 3px;
    padding: 0.3em;
    color: #fff;
}

.table-footer {
    display: flex;
    justify-content: space-between;
}

td.clickable-table-cell {
    cursor: pointer;
    color: #2c8dca;
    text-decoration: underline;
}

.combined-difference {
    font-size: 0.9em;
    font-style: italic;
}

.running-time {
    font-style: italic;
}

.stripes {
    background-image: repeating-linear-gradient(135deg, gray, gray 2px, transparent 2px, transparent 8px);
}

#export-buttons {
    display: inline-block;
}

button.excel-export-button {
    margin: 0.1em !important;
    margin-bottom: 2px !important;
    padding: 0.2em !important;
    background: #00416a !important;
    color: #fff !important;
    font-size: .8em !important;
    font-weight: bold !important;
    font-family: Myriad Pro, Verdana, sans-serif !important;
    font-style: italic !important;
    float: left !important;
    border: none !important;
    border-radius: 0 !important;
}

button.excel-export-button:hover,
button.excel-export-button:active,
button.excel-export-button:focus {
    background: #2c8dca !important;
    text-shadow: none !important;
}

#loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #00416a;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin-left: auto;
    margin-right: auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

#sprint {
    display: flex;
    flex-direction: row;
    margin-top: -1.2em;
}

.sprint-round {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.sprint-heat {
    margin-bottom: 8px;
    margin-right: 8px;
}

.sprint-table {
    width: 100% !important;
    margin: 0;
}

.language-flag {
    height: 1.7em;
    cursor: pointer;
}

.language-bar-float {
    float: right;
    padding: 1px;
    margin-left: 2px;
    white-space: nowrap;
}

.language-bar-absolute {
    position: absolute;
    padding: 1px;
    right: 10px;
    white-space: nowrap;
}

.change-font-size {
    display: inline;
    cursor: pointer;
    vertical-align: top;
}

.change-font-size.increase {
    font-size: 1.7em;
}

.change-font-size.decrease {
    font-size: 1.5em;
}

/*
  Most of the CSS code for modal below is taken from
  https://www.w3schools.com/howto/howto_css_modals.asp
*/
.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-container {
    background-color: #fff;
    margin: 15% auto;
    width: 80%;
}

.modal-content {
    padding: 1em;
    font-size: 0.9em;
}

.modal-header {
    font-size: 1.7em;
    color: #fff;
    background-color: #00416a;
    padding: 0.4em;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

#more-info {
    text-align: center;
    cursor: pointer;
    font-style: italic;
    margin-top: 1em;
    font-size: 0.9em;
}

.modal-content ul li:before {
    padding-right: 1em;
}

.animate-top {
    position: relative;
    animation: animatetop 0.4s
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

@media screen and (max-width: 720px) {
    #event-body {
        width: 100%;
    }

    #index-table-div {
        width: 100%;
    }
}



table.erik-table {
    width: 100%;
}

table.erik-table tr th.sortable {
    cursor: pointer;
    position: relative;
    padding-right: 1.8em;
}

.up-arrow::before {
    content: "▲";
    bottom: 50%;
    position: absolute;
    right: 0.5em;
    font-size: .8em;
    opacity: 0.2;
    line-height: 0.9em;
}

.down-arrow::after {
    content: "▼";
    top: 50%;
    position: absolute;
    right: 0.5em;
    font-size: .8em;
    opacity: 0.2;
    line-height: 1em;
}

.up-arrow-active::before {
    opacity: 1.0;
}

.down-arrow-active::after {
    opacity: 1.0;
}

.pagination {
    background-color: #00416a;
    border: none;
    color: white;
    font-size: 1em;
    margin-right: 0.2em;
    cursor: pointer;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    border-radius: 2px;
}

.pagination.selected-page {
    background-color: #7594b3;
}

.pagination.disabled {
    color: #666;
}

.pagination-container {
    float: right;
    margin-top: 4px;
}

.pagination:hover:not(.disabled):not(.selected-page) {
    background-color: #7594b3;
}

.row-per-page {
    margin-top: 4px;
    cursor: pointer;
    border-radius: 2px;
    padding: 0.2em;
    font-size: 0.8em;
}

.row-per-page-label {
    font-size: 0.8em;
}